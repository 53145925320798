// File automatically generated via terraform, values are set in vault.prod-apsmos.com

export const environment = {
  production: true,
  baseUrl: 'https://aegon-portal-test-cms.aps.one',
  logout: 'https://aegon-portal-test-cms.aps.one/logout',
  graphqlUri: 'https://aegon-portal-test-cms.aps.one/graphql',
  keyCloakLogin: 'https://aegon-portal-test-cms.aps.one/connect/keycloak',
  strapiJwtEndpoint: 'https://aegon-portal-test-cms.aps.one/auth/keycloak/callback',
  cannyTokenEndpoint: 'https://aegon-portal-test-cms.aps.one/canny',
  sentryDSN: '',
  ssoLink: 'sso-test',
  useFreshStatusWidget: 'false',
  siteTitle: 'Aegon Portal'
};
